var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ce-testimonials--container"},[_c('Typography',{staticClass:"ce-testimonials--title",attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h2,"tag":"h2"}},[_vm._v("\n    Déjà 45 000 000 repas servis !\n  ")]),_c('Trustpilot'),_c('Slider',{staticClass:"ce-testimonials--benefits",attrs:{"disabled":!_vm.isMobile,"disable-arrows":"","disable-dots":false,"options":{
      perView: 3,
      breakpoints: {
        500: {
          perView: 1,
        },
      },
    }}},[_c('div',{staticClass:"ce-testimonials--benefit"},[_c('CircleProgress',{attrs:{"percentage":80}}),_c('Typography',{staticClass:"ce-testimonials-benefit--text",attrs:{"tag":"strong"}},[_vm._v("\n        Amélioration de la digestion\n      ")])],1),_c('div',{staticClass:"ce-testimonials--benefit"},[_c('CircleProgress',{attrs:{"percentage":79}}),_c('Typography',{staticClass:"ce-testimonials-benefit--text",attrs:{"tag":"strong"}},[_vm._v("\n        Amélioration qualité du poil et de la peau\n      ")])],1),_c('div',{staticClass:"ce-testimonials--benefit"},[_c('CircleProgress',{attrs:{"percentage":61}}),_c('Typography',{staticClass:"ce-testimonials-benefit--text",attrs:{"tag":"strong"}},[_vm._v("\n        Amélioration de l'appétit\n      ")])],1)]),_c('Slider',{staticClass:"ce-testimonials--videos",attrs:{"disable-dots":false,"disable-arrows":"","options":{
      perView: 5.1,
      breakpoints: {
        500: {
          perView: 1.1,
        },
        1112: {
          perView: 2.1,
          dragThreshold: 120,
        },
      },
    }}},_vm._l((_vm.testimonials),function(ref){
      var videoUrl = ref.videoUrl;
      var name = ref.name;
      var breedName = ref.breedName;
      var special = ref.special;
      var registeredDate = ref.registeredDate;
return _c('InlineVideo',{key:special,staticClass:"ce-testimonials--video",attrs:{"video-url":videoUrl,"registered-date":registeredDate,"breed-name":breedName,"name":name,"special":special}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }