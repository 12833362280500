import {
  CAT,
  PET_SIZE_LARGE,
  PET_SIZE_MEDIUM,
  PET_SIZE_SMALL,
} from '@front/common/constants'

import { imageUrlByBreed } from './constants'

export function adjustFontSizeToFit(containerWidth, text, dampingFactor = 0.5) {
  const minFontSize = 8
  const maxFontSize = 50
  const baseFontSize = 30
  const referenceContainerWidth = 500
  const referenceTextLength = 4
  const containerRatio = containerWidth / referenceContainerWidth
  const textLengthRatio = referenceTextLength / text.length
  const adjustedTextLengthRatio = 1 - (1 - textLengthRatio) * dampingFactor

  let fontSize = baseFontSize * containerRatio

  fontSize *= adjustedTextLengthRatio

  return Math.max(minFontSize, Math.min(maxFontSize, fontSize))
}

export function calculateTextWidth(newText) {
  const referenceText = 'Nina'
  const referenceWidth = 44.9
  const averageCharWidth = referenceWidth / referenceText.length
  const newTextWidth = averageCharWidth * newText.length

  return newTextWidth
}

export function findPetImageUrl(pet, breed) {
  if (!breed) {
    return null
  }

  const sizeMapping = {
    [PET_SIZE_SMALL]: 'PR',
    [PET_SIZE_MEDIUM]: 'MGR',
    [PET_SIZE_LARGE]: 'TGR',
  }

  const breedKey =
    pet.species === CAT
      ? `${pet.species}/${breed.label}`
      : `${pet.species}/${sizeMapping[breed.size.id]}/${breed.label}`

  if (imageUrlByBreed[breedKey]) {
    return imageUrlByBreed[breedKey]
  }

  const fallbackImageUrlBySize = {
    [PET_SIZE_SMALL]:
      'https://japhy-assets.twic.pics/download?id=1k-i-pKT9izGyETNN4asQbAAFaVliGFVO',
    [PET_SIZE_MEDIUM]:
      'https://japhy-assets.twic.pics/download?id=1k40h8ZeyDjKEcSKtgfeI-o29Qx99rUxT',
    [PET_SIZE_LARGE]:
      'https://japhy-assets.twic.pics/download?id=1kK7ySjbVP20qb0kznlTOARNjrW__FRQI',
  }

  return pet.species === CAT
    ? 'https://japhy-assets.twic.pics/download?id=1kMKWGdXkQGXTtvhbZmFvgGwzP8No6VyD'
    : fallbackImageUrlBySize[breed.size.id]
}

export function getPriceByPetInfo({ kilogramWeight, breed, species }) {
  const breedLabelScore = breed.label.length / 15
  const sizeId = breed.size.id

  if (species === CAT) {
    return 7.2122 * Math.log(kilogramWeight) + 11.042 + breedLabelScore
  }

  if (sizeId === 3) {
    return 1.6706 * kilogramWeight + 13.271 + breedLabelScore
  }

  if (sizeId === 2) {
    return 1.7848 * kilogramWeight + 12.017 + breedLabelScore
  }

  return 2.2697 * kilogramWeight + 8.8167 + breedLabelScore
}
