<template>
  <div :class="$style.wrapper">
    <div :class="$style.pictureWrapper">
      <TwicPicture
        :class="$style.picture"
        src="https://japhy-assets.twic.pics/download?id=1ekpi0P03JPDghX1TJXhk2v48bMoZfkhC"
        eager
        alt="Chat et chien qui mangent des croquettes japhy"
        pre-transform="
          crop=1500x1700@47px100p
          @md crop=1500x1900@45px80p
          @xl crop=3000x1800@40px8p
          @2xl none
        "
        ratio="3/4, @md 1, @xl 16/9 @2xl"
        focus="@2xl auto"
      />
    </div>

    <Container :class="$style.content">
      <div :class="$style.innerBox">
        <Typography
          :variant="TYPOGRAPHY_TYPES.h1"
          :class="$style.title"
          tag="h1"
        >
          {{ $i18n('Les croquettes personnalisées pour chiens et chats') }}
        </Typography>

        <div :class="$style.claims">
          <div :class="$style.claim">
            <Icon
              name="check"
              width="30px"
              height="30px"
              :class="$style.icon"
            />

            <Typography :variant="TYPOGRAPHY_TYPES.bodyLarge">
              {{ $i18n('Fabriquées en France') }}
            </Typography>
          </div>

          <div :class="$style.claim">
            <Icon
              name="check"
              width="30px"
              height="30px"
              :class="$style.icon"
            />

            <Typography :variant="TYPOGRAPHY_TYPES.bodyLarge">
              {{ $i18n('Saines') }}
            </Typography>
          </div>

          <div :class="$style.claim">
            <Icon
              name="check"
              width="30px"
              height="30px"
              :class="$style.icon"
            />

            <Typography :variant="TYPOGRAPHY_TYPES.bodyLarge">
              {{ $i18n('Naturelles') }}
            </Typography>
          </div>
        </div>

        <Link :link="SHOWCASE_ROUTE_PROFILE_BUILDER" :class="$style.button">
          <Button id="homepage-cta-hero">{{ $i18n('Je commande') }}</Button>
        </Link>

        <Trustpilot :class="$style.trustpilot" />
      </div>
    </Container>
  </div>
</template>

<script>
import { Trustpilot } from '@front/common/components'
import { Button, Icon, Typography, TYPOGRAPHY_TYPES } from '@front/ui'
import { defineComponent } from '@vue/composition-api'

import Container from '@showcase/components/Container.vue'
import Link from '@showcase/components/Link.vue'
import { SHOWCASE_ROUTE_PROFILE_BUILDER } from '@showcase/core/routes-names'

export default defineComponent({
  components: { Container, Typography, Button, Link, Trustpilot, Icon },
  setup() {
    return {
      TYPOGRAPHY_TYPES,
      SHOWCASE_ROUTE_PROFILE_BUILDER,
    }
  },
})
</script>

<style lang="scss" module>
.picture {
  height: 100vh;
  height: 100svh;
}

.title {
  font-size: 40px;
  line-height: 44px;
  text-align: center;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    line-height: 74px;
    font-size: 74px;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    text-align: left;
  }
}

.pictureWrapper {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

.content {
  $header-size: 78px;

  display: flex;
  padding: 0px $ds-spacing-mobile-small;
  height: calc(100vh - $header-size);
  height: calc(100svh - $header-size);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    $header-size: 88px;

    height: calc(100vh - $header-size);
    height: calc(100svh - $header-size);
    padding: 0px;
    padding-top: $header-size;
  }
}

.innerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: $ds-spacing-mobile-small;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: 600px;
    padding: $ds-spacing-mobile-medium 0px;
    margin: 0px;
    align-items: flex-start;
  }
}

.claims {
  display: none;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: flex;
    gap: $ds-spacing-mobile-small;
    margin-top: $ds-spacing-mobile-small;
  }
}

.claim {
  display: flex;
  align-items: center;
  gap: calc($ds-spacing-mobile-extra-small / 2);
}

.icon {
  stroke-width: 2px;
}

.button {
  margin-top: auto;
  display: flex;
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: $ds-spacing-mobile-medium;
  }
}

.trustpilot {
  margin-top: $ds-spacing-mobile-small;
}
</style>
